.nav {
    margin: .5em 0 0 0;
    font-size: 1.25em;
}

.nav-link {
    margin: 0 1em 0 0;
}
.nav-link:last-child {
    margin-right: 0;
}
