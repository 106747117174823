.resume-entry {
    margin: 0;
    border-bottom: 1px solid #eee;
    padding: 2rem 0 3rem 0;
}

.resume-entry:first-of-type {
    padding-top: 0;
}

@media (min-width: 800px), (min-width: 450px) and (orientation: landscape) {
    /* .resume-entry:first-of-type {
        margin-top: calc(5vw + 2em);
    }

    .resume-entry {
        margin-bottom: 5vw;
    } */
}
