.article {
    margin: 0 auto;
}

.article-nav {
    margin: 1rem;
    font-size: 1rem;
    line-height: 1em;
    display: flex;
    justify-content: space-between;
    color: #888;
    font-weight: 700;
    font-feature-settings: 'dlig';
}

.article-nav-surname {
    display: none;
}

@media (min-width: 400px) {
    .article-nav-surname {
        display: inline;
    }
}

.article-external-preamble {
    display: none;
}

@media (min-width: 600px) {
    .article-external-preamble {
        display: inline;
    }
}

.article-nav-link,
.article-external-link {
    font-weight: 700;
    box-shadow: none;
    color: #000;
}

.article-section-visual,
.article-section-image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

/* .article-section-visual {
} */

.article-section-gallery-nav {
    padding: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    overflow: auto;

    /* HIDE SCROLLBAR */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* HIDE SCROLLBAR */
.article-section-gallery-nav::-webkit-scrollbar {
    display: none;
}

.article-section-gallery-button,
.article-section-gallery-thumbnail {
    --thumbnail-size: 20vw;
    width: var(--thumbnail-size);
    height: var(--thumbnail-size);
    border-radius: 1rem;
    cursor: pointer;
}

.article-section-gallery-button {
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
}

/* .article-section-gallery-button:last-child {
    margin-right: 1rem;
} */

.article-section-gallery-button:after {
    content: '';
    border-radius: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: var(--thumbnail-outline-color) 1px solid;
    opacity: 0.2;

    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.article-section-gallery-button-active:after {
    border-radius: 1.25rem;
    border-width: 2px;
    border-color: var(--thumbnail-outline-active-color);
    opacity: 1;
    top: -.25rem;
    right: -.25rem;
    bottom: -.25rem;
    left: -.25rem;
}

/* .article-section-gallery-thumbnail {
} */

.article-section-image-container {
    width: 100vw;
    height: 100vw;
    background-color: var(--image-bg-color);
    background-size: cover;
    overflow: hidden;
}

.article-section-image-caption {
    margin: 0;
    padding: 0.25em 0.75em;
    border-radius: 1em;
    max-width: calc(100% - 2.5em);
    position: absolute;
    bottom: .5em;
    left: .5em;
    font-size: 1rem;
    background: var(--caption-bg-color);
    color: var(--caption-color);
    z-index: 1;
}

.article-section-image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.article-section-video {
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 1px;
    box-shadow: #000 0 0 0 1px;
}

/* BANNER IMAGES */
.article-section-image-container-banner,
.article-section-image-container-banner .article-section-image,
.article-section-image-container-banner .article-section-video {
    height: 55vw;
}

.article-section-image-container-banner {
    justify-content: center;
    align-content: center;
}

.article-section-image-container-banner picture {
    text-align: center;
}

.article-section-image-container-banner .article-section-image,
.article-section-image-container-banner .article-section-video {
    width: auto;
    position: static;
    display: block;
}
/* END BANNER IMAGES */

/* VIDEO ALIGNMENT */
.article-section-image-container-tv-right {
    justify-content: flex-end;
}

.article-section-video-tv-left,
.article-section-video-tv-right {
    margin: auto 5%;
    height: 75%;
    width: auto;
}

.article-section-video-laptop {
    margin-top: 20%;
    height: auto;
    width: 90%;
    box-shadow: rgba(255,255,255,.1) 0 0 0 1px;
}

.article-section-video-sg-device {
    height: auto;
    width: calc(624/720*100%);
}
/* END VIDEO ALIGNMENT */

.article-section-image-container-phone:after,
.article-section-image-container-background-overlay:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: inherit;
    background-color: transparent;
    pointer-events: none;
}

.article-section-image-container-background-overlay .article-section-video {
    box-shadow: none;
}

.article-section-video-phone {
    height: 85%;
    width: auto;
}

.article-section-content {
    padding: 1em 1em 2em 1em;
    font-size: 1rem;
}

.article-title {
    margin: 0;
    font-size: 2em;
    line-height: 1.5em;
    font-weight: 900;

    color: var(--title-color);
}

.article-role,
.article-date,
.article-blurb,
.article-link,
.article-heading {
    margin: .25em 0 0 0;
    font-size: 1.25em;
    line-height: 1.4em;
}

.article-role {
    font-weight: 700;
    color: var(--role-color);
}

.article-date {
    font-weight: 700;
    color: var(--date-color);
}

.article-blurb,
.article-link {
    font-weight: 500;
    color: var(--blurb-color);
}

.article-blurb {
    margin-bottom: 1em;
}

.article-heading {
    margin: .25em 0;
    font-weight: 700;
    color: var(--heading-color);
}

.article-section-content p {
    font-size: 1em;
    line-height: 1.75em;
}

.article-section-content p,
.article-section-content strong {
    color: var(--body-color);
}

/* for nested sections */
.article-section .article-section .article-section-content {
    padding: 0;
}

.article-section .article-section .article-heading {
    margin: .25em 0;
    font-weight: 800;
    font-size: 1em;
    color: var(--body-color);
}

@media (min-width: 720px) {
    .article-nav {
        margin: 1.5rem;
    }

    .article-section-content {
        padding: 2em 2em 4em 2em;
        font-size: 1.25rem;
    }

    .article-section-gallery-nav {
        padding: 0.5rem 0.5rem .25rem 0.5rem;
        gap: .5rem;
    }

    .article-section-gallery-button,
    .article-section-gallery-thumbnail {
        --thumbnail-size: 5rem;
    }
    
    .article-role,
    .article-date {
        font-weight: 800;
    }
    
    .article-blurb,
    .article-link {
        font-weight: 600;
    }
}

@media (min-width: 1000px) {
    .article,
    .preview {
        --size: 40vw;
    }

    .article {
        padding: 1rem 2rem 2rem 2rem;
    }

    .article-nav {
        margin-left: 0;
        margin-right: 0;
    }

    .article-section {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
    }

    .article-section-visual,
    .article-section-image-container {
        border-radius: 1rem;
        flex-basis: var(--size);
    }

    .article-section-gallery-nav {
        margin: -0.5rem 0 1rem 0;
        padding: 0;
        flex-wrap: wrap;
        overflow: visible;
    }

    .article-section-gallery-button {
        transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .article-section-gallery-button:hover {
        transform: scale(1.05);
    }

    .article-section-gallery-button:active {
        transform: scale(1);
        transition-duration: 0.1s;
    }

    .article-section-visual {
        position: sticky;
        top: 1rem;
    }

    .article-section-image-container {
        margin-bottom: 1rem;
        height: var(--size);
        width: var(--size);
    }

    .article-section-image-container-border:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset rgba(0,0,0,.1) 0 0 0 1px;
        border-radius: inherit;
        pointer-events: none;
    }

    /* BANNER IMAGES */
    .article-section-image-container-banner,
    .article-section-image-container-banner .article-section-image,
    .article-section-image-container-banner .article-section-video {
        height: var(--size);
    }

    .article-section-image-container-banner {
        position: static;
        width: 100%;
    }

    .article-section:has(+ .article-section-image-container) .article-section-content {
        margin-bottom: 1rem;
    }
    /* END BANNER IMAGES */

    .article-section-content-container {
        flex: 1;
    }

    .article-section-content {
        padding-bottom: 1.5em;
        background: var(--bg-color);
    }

    /* NEED TO DO THIS SEPARATELY WHILE FIREFOX DOESN'T SUPPORT :HAS */
    .article-section:first-of-type .article-section-content {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .article-section:last-of-type .article-section-content {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .article-section-image-container + .article-section .article-section-content {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .article-section:has(+ .article-section-image-container) .article-section-content {
        border-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .article-heading {
        margin-top: 0;
    }
}

@media (min-width: 1280px) {
    .article,
    .preview {
        --size: 50vw;
    }

    .article-section-image-container {
        border-radius: 2rem;
    }

    .article-section:first-of-type .article-section-content {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    .article-section:last-of-type .article-section-content {
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }

    .article-section-gallery-nav {
        margin: -0.5rem 0 2rem 0.5rem;
        gap: 0.5rem;
    }

    .article-section-image-caption {
        bottom: 1rem;
        left: 1rem;
        max-width: calc(100% - 1.5em - 1.5rem);
    }

    /* NEED TO DO THIS SEPARATELY WHILE FIREFOX DOESN'T SUPPORT :HAS */
    .article-section-image-container + .article-section .article-section-content {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
    }

    .article-section:has(+ .article-section-image-container) .article-section-content {
        border-bottom-right-radius: 2rem;
        border-bottom-left-radius: 2rem;
    }
}

@media (min-width: 1440px) {
    .article,
    .preview {
        --size: 720px;
    }

    .article {
        margin: 0 auto;
        padding-left: 5rem;
        padding-right: 5rem;
        max-width: 1280px;
    }
}