body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /*--theme: rgb(50,50,50);*/
}

h1 {
    margin: .25em 0;
    line-height: 1;
}

h2 {
    margin: 1em 0 0 0;
}

h3 {
    margin: 0 0 .5rem 0;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #666;
}

h3, h3 a {
    font-weight: 700;
}

h1 small,
h2 small,
h3 small {
    font-size: 50%;
    line-height: 2;
    display: block;
    color: #666;
}

h3 small {
    color: #888;
    font-size: 75%;
    font-weight: 600;
}

p,
li {
    font-weight: 500;
}

a {
    color: #333;
    font-weight: 600;
    text-decoration: none;

    box-shadow: inset rgb(150,0,255) 0 -.15em 0px 0px;
    box-shadow: inset var(--theme) 0 -.15em 0px 0px;
}
a.active {
    box-shadow: inset rgba(255,255,255,.75) 0 -.15em 0px 0px, inset rgb(150,0,255) 0 -.15em 0px 0px;
    box-shadow: inset rgba(255,255,255,.75) 0 -.15em 0px 0px, inset var(--theme) 0 -.15em 0px 0px;
}
a:hover {
    box-shadow: inset rgba(255,255,255,.75) 0 -.15em 0px 0px, inset rgb(150,0,255) 0 -.15em 0px 0px;
    box-shadow: inset rgba(255,255,255,.75) 0 -.15em 0px 0px, inset var(--theme) 0 -.15em 0px 0px;
}

p,
li {
    margin: 0 0 1em 0;
    line-height: 1.5em;
    color: #666;
}
p.no-margin {
    margin: 0;
}

ul {
    padding: 0 0 0 2em;
}

li {
    margin-bottom: .5em;
}

li p {
    margin-bottom: 0;
}

strong {
    color: #333;
    font-weight: 700;
}

::selection {
    color: #fff;
    background: var(--theme, #333); /* WebKit/Blink Browsers */
}
::-moz-selection {
    color: #fff;
    background: var(--theme, #333); /* Gecko Browsers */
}

.nobr {
    white-space: nowrap;
}

.faded {
    opacity: .2;
}

@media (min-width: 750px) {
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    h1 {
        font-size: 2rem;
    }

    p {
        font-size: 1.25em;
    }
}

@media (min-width: 1250px) {
    h1 {
        font-size: 3rem;
    }
}
