.preview {
    margin: 0 0 .5rem 0;
    border-radius: 1rem;
    overflow: hidden;

    /* transition: transform .2s ease-out;
    transition-property: transform, box-shadow; */
}

/* .preview:hover {
    transform: scale(1.005);
    box-shadow: 0px 2rem 5rem 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.preview:active {
    transform: scale(1.001);
    box-shadow: 0px 1rem 3rem 0px rgba(0, 0, 0, 0.15);
    transition-duration: .1s;
} */

.preview .article-section-image-container {
    margin: 0;
}

.preview .article-section-content {
    padding-bottom: 1.5em;
    background: var(--bg-color);
}

.preview .article-blurb {
    margin-bottom: .5em;
}

.preview .feedback {
    display: none;
}

.preview a,
.preview a:hover {
    box-shadow: none;
}

.preview a:hover:not(:active) {
    color: var(--theme);
}

@media (max-width: 999px) {
    .preview .article-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 720px) {
    .preview {
        margin-bottom: 4rem;
    }

    .preview .article-section-content {
        padding-bottom: 2em;
    }
}

@media (min-width: 1000px) {
    .preview {
        overflow: visible;
    }

    .preview .article-section {
        align-items: stretch;
    }

    .preview .article-section-content-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }

    .preview .article-section-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        flex: 1;
    }
}

@media (min-width: 1280px) {
    /* .preview {
        border-radius: 2rem;
    } */

    /* .preview .article-section:has(.feedback) {
        align-items: stretch;
    } */

    /* .preview .article-section-content-container {
        overflow: hidden;
        position: relative;
    } */

    .preview .article-section-content-container {
        max-height: var(--size);
    }

    .preview .feedback {
        display: flex;
        gap: 1rem;
        overflow: hidden;
    }

    .preview .feedback-item {
        margin: 0;
        padding: 1.5em;
        border-radius: 2rem;
        font-size: 1rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        row-gap: .25em;
        column-gap: 5rem;
        background: var(--bg-color);
    }

    .preview .feedback-content {
        margin: 0;
        font-size: 1rem;
    }

    .preview .feedback-body {
        flex: 1;
    }
}

@media (min-width: 1440px) {
    .preview {
        margin: 0 auto 5rem auto;
        max-width: 1280px;
    }
}