
.hero {
    --theme: #FEEB6D;
    --peeking: 5em;

    margin: 0 auto;
    max-width: 35rem;
    padding: 1em;
    font-size: clamp(.5rem, 4vw, 1.25rem);
    height: calc(100svh - var(--peeking));
    min-height: 25em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-home {
    position: relative;
}

.hero-icon {
    position: absolute;
    top: -1.05em;
    left: clamp(-1.3em, -20vw, -1em);
    font-size: clamp(5em, 10vw, 6.5em);
    z-index: -1;
    white-space: nowrap;
}

.hero-icon-resume {
    left: -1.3em;
}

.hero-icon:before,
.hero-icon:after {
    content: '';
    border: 1em solid transparent;
    display: inline-block;
    border-left-color: var(--theme);
    position: absolute;

    /* transition: all cubic-bezier(0, 1.4, 0.9, 0.9) 0.5s; */
}

.hero-icon:before {
    transform: rotate(45deg);
    top: 1em;
    left: calc(1em - 1rem);
}

.hero-icon:after {
    top: .58em;
    left: 2em;
}

.hero-icon:after,
.hero-icon-resume:before {
    border-width: 0.7em;
    border-left-width: 1.2em;
}

.hero-icon-resume:before {
    top: .9em;
    left: 1.1em;
    transform: rotate(0deg) translateY(-1rem);
}

.hero-icon-resume:after {
    opacity: 0;
}

.home-title {
    margin: 0 0 .25em 0;
    font-weight: 900;
    font-size: clamp(2em, 4vw, 2.4em);
    line-height: 1;
}

.home-subtitle {
    margin: .5em 0 .5em 0;
    font-size: clamp(1.5em, 3vw, 2em);
    line-height: 1;
    color: inherit;
    opacity: .75;
    font-weight: 700;
}

.home-blurb {
    margin: 0;
    font-size: 1.25em;
    line-height: 1.5;
}
