.app {
    position: relative;
}

.container {
    padding: 1em;
}

.container.container-constrained {
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    max-width: 35rem;
}

@media (orientation: landscape) {
    .container {
        padding: 3.25em 1em 1em 1em;
    }
}

@media (min-width: 700px) {
    .container {
        padding: calc(3vw + 3em) 5vw 2vw 5vw;
    }
}

@media (min-width: 800px), (min-width: 450px) and (orientation: landscape) {
    .container {
        padding: 5vw;
    }
}
