.feedback-item {
    margin: 0 0 1em 0;
}

.feedback-content {
    margin: 0;
}

.feedback-content.feedback-stars {
    color: var(--heading-color);
}