.preview,
.article {
    --theme:          #333;
    --body-color:     #000;
    --bg-color:       #f1f1f1;
    --image-bg-color: var(--bg-color);

    --title-color:                      var(--theme);
    --role-color:                       var(--theme);
    --date-color:                       var(--theme);
    --blurb-color:                      var(--body-color);
    --heading-color:                    var(--theme);
    --thumbnail-outline-color:          #888;
    --thumbnail-outline-active-color:   var(--theme);
    --caption-bg-color:                 var(--theme);
    --caption-color:                    #fff;
}

@media (min-width: 720px) {
    .preview,
    .article {
        --bg-color: #f8f8f8;
    }
}

.mathspace {
    --theme: #5B51A8;
}

.stan {
    --theme:          #0174FA;
    --image-bg-color: #000f22;
}

.optus {
    --theme:        #18265A;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.trolleys,
.woolies {
    --theme:        #408649;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.rest {
    --theme:        #2961AA;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.burst {
    --theme:        #0185FF;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.beetroot {
    --theme:        #E81957;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.att {
    --theme:        #469DD6;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.fa {
    --theme:        #DB1B4C;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.playstation {
    --theme:        #205CBC;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}

.thumbcinema {
    --theme:        #567BF1;
    /* --body-color:   #000;
    --bg-color:     #f8f8f8; */
}